export function loading() {

    var loader = document.querySelector('.j-loader');
    var header = document.querySelector('.j-header');
    /* ロード画面を非表示にする処理 */
    const stopload = () => {
        loader.classList.remove('is-loading');
        header.classList.add('is-loading');
    }

    /* 読み込み完了 */
    window.addEventListener('load', stopload);

    /* 10秒経ったら強制的にロード画面を非表示にする */
    // setTimeout('stopload()', 3000);

}