
export function time() {

    let twoDigit = (num) => {
        let ret;
        if( num < 10 )
            ret = "0" + num;
        else
            ret = num;
        return ret;
    }

    let displayTime = () => {
        // // 時刻を表示する要素を取得
        const clock = document.querySelector('.js-time');

        let nowTime = new Date();
        let nowHour = twoDigit( nowTime.getHours() );
        let nowMin  = twoDigit( nowTime.getMinutes() );
        let nowSec  = twoDigit( nowTime.getSeconds() );
        let msg = "" + nowHour + " : " + nowMin + " : " + nowSec;

        // 要素に時刻を表示
        clock.innerText = msg;
    }

    displayTime();

    // 1秒ごとにdisplayTime関数を呼び出す
    setInterval(displayTime, 1000);
}