export function dragscroll() {
    let targets = document.querySelectorAll(".js-dragscroll");
    let target;
    targets.forEach(function (e) {
    e.addEventListener("mousedown", function (event) {
        event.preventDefault();
        target = e;
        e.dataset.down = true;
        e.dataset.move = false;
        e.dataset.x = event.clientX;
        e.dataset.scrollleft = e.scrollLeft;
        return false;
    });
    });
    document.addEventListener("mousemove", function (event) {
    if (target && target.dataset.down) {
        event.preventDefault();
        let move_x = target.dataset.x - event.clientX;
        if (move_x !== 0) {
        target.dataset.move = true;
        } else {
        return;
        }
        target.scrollLeft = parseInt(target.dataset.scrollleft) + move_x;
        return false;
    }
    });
    document.addEventListener("mouseup", function (event) {
    if (target) {
        target.dataset.down = false;
        target = null;
    }
    return false;
    });
}