import Swiper from 'swiper/swiper-bundle';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function mv() {
    let mySwiper = new Swiper('.j-mvSlider', {
        speed: 1000,
        spaceBetween: 0,
        effect: "fade",
        allowTouchMove: false,
        // navigation: {
        // nextEl: ".swiper-button-next",
        // prevEl: ".swiper-button-prev",
        // },
        autoplay: {
        delay: 3500,
        disableOnInteraction: false,
        },
        pagination: {
            el: '.p-home_mv_slider_num_main',
            type: 'fraction',
            formatFractionCurrent(number) {
                return number.toLocaleString('en', { minimumIntegerDigits: 2 })
            },
            formatFractionTotal(number) {
                return this.formatFractionCurrent(number)
            }
        }
    });


    window.addEventListener("load", function(){
        gsap.registerPlugin(ScrollTrigger);

        const mvEl = document.querySelector('.j-mv');

        gsap.to(mvEl, {
            ease: 'none',
            scrollTrigger: {
                // markers: true,
                trigger: mvEl,
                start: 'top top',
                end: 'bottom top',
                scrub: true,
                pin: true,
                anticipatePin: 1,
                invalidateOnRefresh: true,
                // markers: true
            },
        });
        // let window_w = window.innerWidth;
        // if(window_w > 768) {

        // }
    });
}